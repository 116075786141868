@keyframes highlightImg {
  from {
    border: 1px solid #e0e0e0;
  } to {
    border: 1px solid #5b9ff8;
  }
}

@keyframes unhighlightImg {
  from {
    border: 1px solid #5b9ff8;
  } to {
    border: 1px solid #e0e0e0;
  }
}

.portfolio-item-img {
  animation: unhighlightImg 1s forwards;
  border: 1px solid #e0e0e0;
}

.portfolio-item-img:hover {
  animation: highlightImg 1s forwards;
  border: 1px solid #5b9ff8;
}
